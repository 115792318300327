import React from "react";
import { FormControl, FormHelperText } from "@mui/material";
import TextField from "@mui/material/TextField";

const InputText = (props) => {
  const {
    required,
    sx,
    size,
    label,
    name,
    variant,
    placeholder,
    id,
    onChange,
    value,
    disabled,
    error,
    type,
    errorMessage,
    accept,
    InputProps,
  } = props;

  return (
    <FormControl fullWidth sx={{ mb: 2 }}>
      <TextField
        fullWidth
        required={required}
        size={size}
        label={label}
        name={name}
        variant={variant}
        placeholder={placeholder}
        id={id}
        onChange={onChange}
        value={value}
        error={error}
        type={type}
        disabled={disabled}
        accept={accept}
        InputProps={InputProps}
        sx={sx}
      />
      <FormHelperText sx={{ color: "error.main", ml: 0 }}>
        {errorMessage}
      </FormHelperText>
    </FormControl>
  );
};

export default InputText;
