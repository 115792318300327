import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteFamily,
  fetchFamilyList,
  getFamilyList,
  getLoadingState,
} from "../../Redux/Reducer/FamilyReducer";
import { RowOptions } from "../../Common/Action-Popover/Action-Popover";
import { UserActions } from "../../Utils/helper";
import DeleteConfirmation from "../../Common/DeleteConfirmed/DeleteConfirmed";
import { DataGridTable } from "../../Common/Data-Grid/DataGrid";
import MUIGrid from "../../Common/MuiGrid/MuiGrid";
import CustomHeader from "../../Components/CutomHeader/CustomHeader";
import withLoader from "../../Components/HOC/WithLoader";

const MemberList = (props) => {
  /**
   * props
   */
  const { setLoading } = props;

  /**
   * hooks
   */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector(getLoadingState);

  const familyList = useSelector(getFamilyList);
  const viewFamilyList = familyList?.data?.data;
  let totalCount = familyList?.data?.pagination?.total_records;

  /**
   * states
   */
  const [open, setOpen] = useState(false);
  const [selectedFamily, setSelectedFamily] = useState();
  const [fetchDataTrigger, setFetchDataTrigger] = useState(0);
  const [value, setValue] = useState("");
  const [filters, setFilters] = useState({
    fname: "",
    lname: "",
    mname: "",
    mobile_no: "",
    address: "",
    gender: "",
    permananent_address: "",
    residential_address: "",
    residential_pincode: "",
    permananent_pincode: "",
    email: "",
    age: "",
    adhaar_no: "",
    voter_no: "",
    occupation: "",
    birthdate: "",
    marital_status: "",
    marrige_date: ""
  });
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  /**
   * Pagination functionality
   */
  const per_Page = paginationModel.pageSize;
  const page = paginationModel.page;

  /**
   * useEffect
   */
  useEffect(() => {
    const per_Page = paginationModel.pageSize;
    const page = paginationModel.page + 1;

    dispatch(
      fetchFamilyList({
        page: page,
        per_Page: per_Page,
        filters: filters,
        filter: value,
      })
    );
  }, [fetchDataTrigger, per_Page, page, filters, value]);

  /**
   * setLoading
   */
  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  /**
   * handle Action
   * @param {*} data
   * @param {*} action
   */
  const handleAction = (data, action) => {
    switch (action) {
      case UserActions.DELETE:
        handleFamilyDeletion(data);
        break;
      case UserActions.EDIT:
        navigate(`/edit-member/${data.id}`);
        break;
      case UserActions.SHOW_DETAILS:
        navigate(`/show-member/${data.id}`);
        break;
      default:
        break;
    }
  };

  /**
   * Action
   * @param {*} data
   * @param {*} action
   */
  const handleActions = (data, action) => {
    handleAction(data, action);
  };

  /**
   * open delete modal
   */
  const handleClickOpen = () => {
    setOpen(true);
  };

  /**
   * handle Delete
   * @param {*} data
   */
  const handleFamilyDeletion = (data) => {
    setSelectedFamily(data);
  };

  const handleDeleteFamily = (data) => {
    dispatch(deleteFamily(data.id)).then((res) => {
      triggerDataFetch();
    });
  };

  /**
   * button click loading
   */
  const triggerDataFetch = () => {
    setFetchDataTrigger(fetchDataTrigger + 1);
  };

  /**
   * handle closed
   */
  const handleClose = () => {
    setOpen(false);
  };

  /**
   * loading
   */
  if (!viewFamilyList) {
    return <div></div>;
  }

  /**
   * handleFilter onchange event
   */
  const handleFilter = (event) => {
    const { name, value } = event.target;
    setFilters({ ...filters, [name]: value });
  };

  const columns = [
    {
      width: 150,
      field: "fname",
      headerName: "First Name",
      sortable: false,
      renderHeader: (params) => (
        <CustomHeader
          filters={filters}
          handleFilter={handleFilter}
          name="fname"
          label="First Name"
        />
      ),
    },
    {
      width: 150,
      field: "mname",
      headerName: "Middle Name",
      sortable: false,
      renderHeader: (params) => (
        <CustomHeader
          filters={filters}
          handleFilter={handleFilter}
          name="mname"
          label="Middle Name"
        />
      ),
    },
    {
      width: 150,
      field: "lname",
      headerName: "Last Name",
      sortable: false,
      renderHeader: (params) => (
        <CustomHeader
          filters={filters}
          handleFilter={handleFilter}
          name="lname"
          label="Last Name"
        />
      ),
    },
    {
      width: 150,
      field: "age",
      headerName: "Age",
      sortable: false,
      renderHeader: (params) => (
        <CustomHeader
          filters={filters}
          handleFilter={handleFilter}
          name="age"
          label="Age"
        />
      ),
    },
    {
      width: 150,
      field: "gender",
      headerName: "Gender",
      sortable: false,
      renderHeader: (params) => (
        <CustomHeader
          filters={filters}
          handleFilter={handleFilter}
          name="gender"
          label="Gender"
        />
      ),
    },
    {
      width: 150,
      field: "mobile_no",
      headerName: "Mobile No.",
      sortable: false,
      renderHeader: (params) => (
        <CustomHeader
          filters={filters}
          handleFilter={handleFilter}
          name="mobile_no"
          label="Mobile No."
        />
      ),
    },
    {
      width: 150,
      field: "email",
      headerName: "Email",
      sortable: false,
      renderHeader: (params) => (
        <CustomHeader
          filters={filters}
          handleFilter={handleFilter}
          name="email"
          label="Email"
        />
      ),
    },
    {
      width: 200,
      field: "permananent_address",
      headerName: "Permanent Address",
      sortable: false,
      renderHeader: (params) => (
        <CustomHeader
          filters={filters}
          handleFilter={handleFilter}
          name="permananent_address"
          label="Permanent Address"
        />
      ),
    },
    {
      width: 200,
      field: "permananent_pincode",
      headerName: "Permanent Pincode",
      sortable: false,
      renderHeader: (params) => (
        <CustomHeader
          filters={filters}
          handleFilter={handleFilter}
          name="permananent_pincode"
          label="Permanent Pincode"
        />
      ),
    },
    {
      width: 200,
      field: "residential_address",
      headerName: "Residential Address",
      sortable: false,
      renderHeader: (params) => (
        <CustomHeader
          filters={filters}
          handleFilter={handleFilter}
          name="residential_address"
          label="Residential Address"
        />
      ),
    },
    {
      width: 200,
      field: "residential_pincode",
      headerName: "Residential Pincode",
      sortable: false,
      renderHeader: (params) => (
        <CustomHeader
          filters={filters}
          handleFilter={handleFilter}
          name="residential_pincode"
          label="Residential Pincode"
        />
      ),
    },
    {
      width: 150,
      field: "adhaar_no",
      headerName: "Aadhaar No.",
      sortable: false,
      renderHeader: (params) => (
        <CustomHeader
          filters={filters}
          handleFilter={handleFilter}
          name="adhaar_no"
          label="Aadhaar No."
        />
      ),
    },
    {
      width: 150,
      field: "voter_no",
      headerName: "Voter ID",
      sortable: false,
      renderHeader: (params) => (
        <CustomHeader
          filters={filters}
          handleFilter={handleFilter}
          name="voter_no"
          label="Voter ID"
        />
      ),
    },
    {
      width: 150,
      field: "birthdate",
      headerName: "Birth Date",
      sortable: false,
      renderHeader: (params) => (
        <CustomHeader
          filters={filters}
          handleFilter={handleFilter}
          name="birthdate"
          label="Birth Date"
        />
      ),
      renderCell: ({ row }) => {
        const formatDate = (dateString) => {
          if (dateString === '0000-00-00') {
            return '';
          }
          const date = new Date(dateString);
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const year = date.getFullYear();

          return `${day}/${month}/${year}`;
        };

        return (
          <Typography>
            {row?.birthdate ? formatDate(row.birthdate) : ''}
          </Typography>
        );
      },
    },
    {
      width: 150,
      field: "marital_status",
      headerName: "Marital Status",
      sortable: false,
      renderHeader: (params) => (
        <CustomHeader
          filters={filters}
          handleFilter={handleFilter}
          name="marital_status"
          label="Marital Status"
        />
      ),
    },
    {
      width: 170,
      field: "marrige_date",
      headerName: "Anniversary Date",
      sortable: false,
      renderHeader: (params) => (
        <CustomHeader
          filters={filters}
          handleFilter={handleFilter}
          name="marrige_date"
          label="Anniversary Date"
        />
      ),
      renderCell: ({ row }) => {
        const formatDate = (dateString) => {
          if (dateString === '0000-00-00') {
            return '';
          }
          const date = new Date(dateString);
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const year = date.getFullYear();

          return `${day}/${month}/${year}`;
        };

        return (
          <Typography>
            {row?.marrige_date ? formatDate(row.marrige_date) : ''}
          </Typography>
        );
      },
    },
    {
      width: 150,
      field: "occupation",
      headerName: "Occupation",
      sortable: false,
      renderHeader: (params) => (
        <CustomHeader
          filters={filters}
          handleFilter={handleFilter}
          name="occupation"
          label="Occupation"
        />
      ),
    },
    {
      width: 150,
      field: "actions",
      headerName: "Actions",
      renderHeader: (params) => <CustomHeader label="Actions" />,
      sortable: false,
      align: "left",
      renderCell: ({ row }) => (
        <RowOptions
          handleClickOpen={handleClickOpen}
          handleUserAction={handleActions}
          selectionModel={row}
        />
      ),
    },
  ];

  /**
   * get filter value
   */
  const handleFilterAll = (val) => {
    setValue(val);
  };

  const handleAddFamilyMember = () => {
    navigate("/add-member");
  };
  return (
    <MUIGrid item xs={12}>
      <Box sx={{ width: "100%", p: 2 }}>
        <DataGridTable
          rows={viewFamilyList}
          columns={columns}
          getRowId={(row) => row.id}
          onRowSelectionModelChange={undefined}
          selectionModel={undefined}
          title={"Family Member List"}
          rowCount={totalCount}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          page={paginationModel.page}
          searchlabel={"Search"}
          columnHeaderHeight={80}
          handleFilter={handleFilterAll}
          value={value}
          disableColumnSort={true}
          handleAdd={handleAddFamilyMember}
          buttonName={"Add Member"}
        />
        <DeleteConfirmation
          title="Delete family"
          contentText="Are you sure you want to delete family?"
          no="No"
          yes="Yes"
          selectedData={selectedFamily}
          handleDelete={handleDeleteFamily}
          open={open}
          handleClose={handleClose}
        />
      </Box>
    </MUIGrid>
  );
};

export default withLoader(MemberList);
