/**
 * Save access token to localStorage
 * @param key
 * @param token
 */
export const saveAccessToken = (key, token) => {
  localStorage.setItem(key, token);
};

/**
 * get access token to localStorage
 * @returns
 */
export const getAccessToken = () => {
  const accessToken = localStorage.getItem("token");
  return accessToken;
};

/**
 * Create a UserActions object for Crud operation Action like e.g.  [Add, Edit, Delete and show]
 */
export const UserActions = {
  SHOW_DETAILS: 1,
  ADD: 2,
  EDIT: 3,
  DELETE: 4,
};

/**
 * Regular expressions for validate email addresses
 */
export const isValidEmail = (email) => {
  let regex = new RegExp("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$");
  if (!email || email.trim() === "") {
    return "Email is required";
  } else if (!regex.test(email)) {
    return "Please enter valid email";
  }
  return "";
};

/**
 * Password validation regular expression
 * @param {*} password
 * @returns
 */
export const validatePassword = (password) => {
  if (!password || password.trim() === "") {
    return "Password is required";
  } else if (!/[A-Z]/.test(password)) {
    return "Password must contain at least one uppercase letter";
  } else if (password.length < 6) {
    return "Password must be at least 6 characters long";
  } else if (!/[@$#%]/.test(password)) {
    return "Password must contain at least one of the following special characters: $, @, #, or %";
  } else if (!/\d/.test(password)) {
    return "Password must contain at least one digit";
  }
  return "";
};

/**
 * Password validation regular expression
 * @param {*} mobileNumber
 * @returns
 */
export const validateMobileNumber = (mobileNumber) => {
  if (!mobileNumber || mobileNumber.trim() === "") {
    return "Mobile number is required";
  } else if (!/^[789]\d{9}$/.test(mobileNumber)) {
    return "Enter a valid 10-digit mobile number starting with 7, 8, or 9";
  }
  return "";
};
