import { ThemeProvider, createTheme } from "@mui/material";
import React from "react";
import { purple } from "@mui/material/colors";

const Theme = ({ children }) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: purple[500],
      },
      secondary: {
        main: "#f44336",
      },
    },
  });
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;
