import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getAccessToken } from "../Utils/helper";

function AuthRoute() {
  const token = getAccessToken();
  if (!token) {
    return <Outlet />;
  }
  return <Navigate to="/dashboard" replace={true} />;
}

export default AuthRoute;
