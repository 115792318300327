import React from "react";
import { FormControl, FormHelperText } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/system";

const TextArea = (props) => {
  const {
    required,
    sx,
    size,
    label,
    name,
    variant,
    placeholder,
    id,
    onChange,
    value,
    disabled,
    error,
    type,
    errorMessage,
    accept,
  } = props;

  return (
    <FormControl fullWidth sx={{ mb: 2 }}>
      <Box width="100%">
        <TextField
          required={required}
          fullWidth
          size={size}
          label={label}
          name={name}
          variant={variant}
          placeholder={placeholder}
          id={id}
          onChange={onChange}
          value={value}
          error={error}
          type={type}
          disabled={disabled}
          accept={accept}
          sx={sx}
          InputProps={{
            rows: 3,
            multiline: true,
            inputComponent: "textarea",
          }}
        />
      </Box>
      <FormHelperText sx={{ color: "error.main", ml: 0 }}>
        {errorMessage}
      </FormHelperText>
    </FormControl>
  );
};

export default TextArea;
