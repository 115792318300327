import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { DataGridTable } from "../../Common/Data-Grid/DataGrid";
import MUIGrid from "../../Common/MuiGrid/MuiGrid";
import CustomHeader from "../../Components/CutomHeader/CustomHeader";
import {
  fetchAllFamilyList,
  getAllFamilyMemberList,
  getLoadingState,
} from "../../Redux/Reducer/AllFamilyReducer";
import withLoader from "../../Components/HOC/WithLoader";

const AllMemberList = (props) => {
  /**
   * props
   */
  const { setLoading } = props;
  /**
   * hooks
   */
  const dispatch = useDispatch();
  const isLoading = useSelector(getLoadingState);

  const familyList = useSelector(getAllFamilyMemberList);
  const viewFamilyList = familyList?.data?.data;
  let totalCount = familyList?.data?.pagination?.total_records;

  /**
   * states
   */
  const [fetchDataTrigger, setFetchDataTrigger] = useState(0);
  const [value, setValue] = useState("");
  const [filters, setFilters] = useState({
    fname: "",
    lname: "",
    mname: "",
    mobile_no: "",
    address: "",
    gender: "",
    permananent_address: "",
    residential_address: "",
    residential_pincode: "",
    permananent_pincode: "",
    email: "",
    age: "",
    adhaar_no: "",
    voter_no: "",
    occupation: "",
    birthdate: "",
    marital_status: "",
    marrige_date: ""
  });
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  /**
   * Pagination functionality
   */
  const per_Page = paginationModel.pageSize;
  const page = paginationModel.page;

  /**
   * useEffect
   */
  useEffect(() => {
    const per_Page = paginationModel.pageSize;
    const page = paginationModel.page + 1;

    dispatch(
      fetchAllFamilyList({
        page: page,
        per_Page: per_Page,
        filters: filters,
        filter: value,
      })
    );
  }, [dispatch, fetchDataTrigger, per_Page, page, filters, value]);

  /**
   * setLoading
   */
  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  /**
   * loading
   */
  if (!viewFamilyList) {
    return <div></div>;
  }

  /**
   * handleFilter onchange event
   */
  const handleFilter = (event) => {
    const { name, value } = event.target;
    setFilters({ ...filters, [name]: value });
  };

  const columns = [
    {
      width: 150,
      field: "fname",
      headerName: "First Name",
      sortable: false,
      renderHeader: (params) => (
        <CustomHeader
          filters={filters}
          handleFilter={handleFilter}
          name="fname"
          label="First Name"
        />
      ),
    },
    {
      width: 150,
      field: "mname",
      headerName: "Middle Name",
      sortable: false,
      renderHeader: (params) => (
        <CustomHeader
          filters={filters}
          handleFilter={handleFilter}
          name="mname"
          label="Middle Name"
        />
      ),
    },
    {
      width: 150,
      field: "lname",
      headerName: "Last Name",
      sortable: false,
      renderHeader: (params) => (
        <CustomHeader
          filters={filters}
          handleFilter={handleFilter}
          name="lname"
          label="Last Name"
        />
      ),
    },
    {
      width: 150,
      field: "mobile_no",
      headerName: "Mobile No.",
      sortable: false,
      renderHeader: (params) => (
        <CustomHeader
          filters={filters}
          handleFilter={handleFilter}
          name="mobile_no"
          label="Mobile No."
        />
      ),
    },
    {
      width: 200,
      field: "residential_address",
      headerName: "Residential Address",
      sortable: false,
      renderHeader: (params) => (
        <CustomHeader
          filters={filters}
          handleFilter={handleFilter}
          name="residential_address"
          label="Residential Address"
        />
      ),
    },
    {
      width: 200,
      field: "residential_pincode",
      headerName: "Residential Pincode",
      sortable: false,
      renderHeader: (params) => (
        <CustomHeader
          filters={filters}
          handleFilter={handleFilter}
          name="residential_pincode"
          label="Residential Pincode"
        />
      ),
    },
    {
      width: 200,
      field: "permananent_address",
      headerName: "Permanent Address",
      sortable: false,
      renderHeader: (params) => (
        <CustomHeader
          filters={filters}
          handleFilter={handleFilter}
          name="permananent_address"
          label="Permanent Address"
        />
      ),
    },
    {
      width: 200,
      field: "permananent_pincode",
      headerName: "Permanent Pincode",
      sortable: false,
      renderHeader: (params) => (
        <CustomHeader
          filters={filters}
          handleFilter={handleFilter}
          name="permananent_pincode"
          label="Permanent Pincode"
        />
      ),
    },
    {
      width: 150,
      field: "occupation",
      headerName: "Occupation",
      sortable: false,
      renderHeader: (params) => (
        <CustomHeader
          filters={filters}
          handleFilter={handleFilter}
          name="occupation"
          label="Occupation"
        />
      ),
    },
    {
      width: 150,
      field: "gender",
      headerName: "Gender",
      sortable: false,
      renderHeader: (params) => (
        <CustomHeader
          filters={filters}
          handleFilter={handleFilter}
          name="gender"
          label="Gender"
        />
      ),
    },
    {
      width: 150,
      field: "email",
      headerName: "Email",
      sortable: false,
      renderHeader: (params) => (
        <CustomHeader
          filters={filters}
          handleFilter={handleFilter}
          name="email"
          label="Email"
        />
      ),
    },
    {
      width: 150,
      field: "adhaar_no",
      headerName: "Aadhaar No.",
      sortable: false,
      renderHeader: (params) => (
        <CustomHeader
          filters={filters}
          handleFilter={handleFilter}
          name="adhaar_no"
          label="Aadhaar No."
        />
      ),
    },
    {
      width: 150,
      field: "voter_no",
      headerName: "Voter ID",
      sortable: false,
      renderHeader: (params) => (
        <CustomHeader
          filters={filters}
          handleFilter={handleFilter}
          name="voter_no"
          label="Voter ID"
        />
      ),
    },
    {
      width: 150,
      field: "birthdate",
      headerName: "Birth Date",
      sortable: false,
      renderHeader: (params) => (
        <CustomHeader
          filters={filters}
          handleFilter={handleFilter}
          name="birthdate"
          label="Birth Date"
        />
      ),
      renderCell: ({ row }) => {
        const formatDate = (dateString) => {
          if (dateString === '0000-00-00') {
            return '';
          }
          const date = new Date(dateString);
          const day = String(date.getDate()).padStart(2, '0'); 
          const month = String(date.getMonth() + 1).padStart(2, '0'); 
          const year = date.getFullYear();

          return `${day}/${month}/${year}`;
        };

        return (
          <Typography>
            {row?.birthdate ? formatDate(row?.birthdate) : ''}
          </Typography>
        );
      },
    },
    {
      width: 150,
      field: "marital_status",
      headerName: "Marital Status",
      sortable: false,
      renderHeader: (params) => (
        <CustomHeader
          filters={filters}
          handleFilter={handleFilter}
          name="marital_status"
          label="Marital Status"
        />
      ),
    },
    {
      width: 170,
      field: "marrige_date",
      headerName: "Anniversary date",
      sortable: false,
      renderHeader: (params) => (
        <CustomHeader
          filters={filters}
          handleFilter={handleFilter}
          name="marrige_date"
          label="Anniversary date"
        />
      ),
      renderCell: ({ row }) => {
        const formatDate = (dateString) => {
          if (dateString === '0000-00-00') {
            return '';
          }
          const date = new Date(dateString);
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const year = date.getFullYear();

          return `${day}/${month}/${year}`;
        };

        return (
          <Typography>
            {row?.marrige_date ? formatDate(row?.marrige_date) : ''}
          </Typography>
        );
      },
    },
    {
      width: 150,
      field: "age",
      headerName: "Age",
      sortable: false,
      renderHeader: (params) => (
        <CustomHeader
          filters={filters}
          handleFilter={handleFilter}
          name="age"
          label="Age"
        />
      ),
    },
    {
      width: 150,
      field: "family_head",
      headerName: "Family Head",
      sortable: false,
      renderHeader: (params) => (
        <CustomHeader
          filters={filters}
          handleFilter={handleFilter}
          name="family_head"
          label="Family Head"
        />
      ),
    },
  ];

  /**
   * get filter value
   */
  const handleFilterAll = (val) => {
    setValue(val);
  };

  return (
    <MUIGrid item xs={12}>
      <Box sx={{ width: "100%", p: 2 }}>
        <DataGridTable
          rows={viewFamilyList}
          columns={columns}
          getRowId={(row) => row.id}
          title={"All Member List"}
          rowCount={totalCount}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          page={paginationModel.page}
          searchlabel={"Search"}
          columnHeaderHeight={80}
          handleFilter={handleFilterAll}
          value={value}
        />
      </Box>
    </MUIGrid>
  );
};

export default withLoader(AllMemberList);
