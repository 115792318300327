import { combineReducers, configureStore } from "@reduxjs/toolkit";
import familyReducer from "../Reducer/FamilyReducer";
import authReducer from "../Reducer/AuthReducer";
import allFamilyReducer from "../Reducer/AllFamilyReducer";
import BirthdayAnniversaryReducer from "../Reducer/BirthdayAnniversaryReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  viewFamily: familyReducer,
  viewAllFamily: allFamilyReducer,
  birthdayAnniversary: BirthdayAnniversaryReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
