import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getAccessToken } from "../Utils/helper";

function ProtectedRoute() {
  const token = getAccessToken();
  if (!token) {
    return <Navigate to="/signin" replace={true} />;
  }
  if (token) {
    return <Outlet />;
  }

  return <Navigate to="/" replace={true} />;
}

export default ProtectedRoute;
