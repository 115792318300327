import React from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

const CustomHeader = ({ filters, handleFilter, name, label }) => {
  const isID = label === "ID";
  const isAction = label === "Actions";

  return (
    <div>
      {!isID && !isAction && (
        <TextField
          placeholder={label}
          name={name}
          autoComplete="off"
          value={filters[name]}
          onChange={handleFilter}
          sx={{ "& .MuiInputBase-input": { padding: "5px" } }}
        />
      )}
      <Typography variant="body1">{label}</Typography>
    </div>
  );
};

export default CustomHeader;
