import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getBirthdayAnniversaryURL } from "../../Services/ApiConfig";
import axiosInstance from "../../Services/API";

/**
 * Define the initialstate
 */
const initialState = {
  birthdayAnniversaryList: [],
  isLoading: false,
  isLoadingOrg: false,
  error: {
    message: "",
    code: "",
  },
};

/**
 * fetch birthday anniversary list API
 */
export const fetchBirthdayAnniversaryList = createAsyncThunk(
  "/api/wish",
  async () => {
    try {
      const response = await axiosInstance.get(getBirthdayAnniversaryURL());
      return response.data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

/**
 * Define the slice using createSlice from @reduxjs/toolkit
 */
const birthdayAnniversarySlice = createSlice({
  name: "birthdayAnniversary",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBirthdayAnniversaryList.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(fetchBirthdayAnniversaryList.fulfilled, (state, action) => {
        state.birthdayAnniversaryList = action.payload;
        state.isLoading = false;
      })

      .addCase(fetchBirthdayAnniversaryList.rejected, (state) => {
        state.isLoading = false;
        state.birthdayAnniversaryList = [];
      });
  },
});

export const getbirthdayAnniversaryList = (state) =>
  state.birthdayAnniversary.birthdayAnniversaryList;
export const getLoadingState = (state) => state.birthdayAnniversary.isLoading;

/**
 * Export the reducer
 */
export default birthdayAnniversarySlice.reducer;
