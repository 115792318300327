import BoxComponent from "../MuiBox/MuiBox";
import CustomTypography from "../Typography/Typography";
import { FormControl, MenuItem, TextField } from "@mui/material";
import TextFieldComponent from "../TextFieldComponent/TextFieldComponent";
import MUIButton from "../Button/Button";

const TableHeader = (props) => {
  const {
    title,
    searchlabel,
    value,
    handleFilter,
    departmentMapInputSelect,
    dropdownGroupDepartmentMapList,
    buttonName,
    handleAdd,
    component,
    href,
  } = props;

  return (
    <BoxComponent
      sx={{
        py: 1,
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <BoxComponent sx={{ width: "100%" }}>
        <BoxComponent
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "left",
          }}
        >
          <BoxComponent sx={{ flexGrow: 1, marginBottom: { xs: 2, sm: 0 } }}>
            <CustomTypography variant="h5">{title}</CustomTypography>
          </BoxComponent>
          <BoxComponent
            sx={{
              flexBasis: { xs: "100%", sm: "auto" },
              marginRight: { xs: 0, sm: !buttonName ? 0 : 4 },
              marginBottom: { xs: 2, sm: 0 },
            }}
          >
            {departmentMapInputSelect ? (
              <FormControl size="small">
                <TextField
                  fullWidth
                  autoComplete="off"
                  select
                  size={"small"}
                  label={"Search"}
                  value={value}
                  onChange={(e) => handleFilter(e.target.value)}
                  placeholder={"Search"}
                >
                  {dropdownGroupDepartmentMapList?.map((row, item) => (
                    <MenuItem key={item} value={row.id}>
                      {row.group}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            ) : (
              <TextFieldComponent
                label={searchlabel}
                placeholder={searchlabel}
                sx={{ mb: 2 }}
                size="small"
                value={value}
                onChange={(e) => handleFilter(e.target.value)}
              />
            )}
          </BoxComponent>
          <BoxComponent>
            {buttonName ? (
              <BoxComponent sx={{ minWidth: 120, pb: 2 }}>
                <MUIButton
                  variant="contained"
                  color="primary"
                  title={buttonName}
                  onClick={handleAdd}
                  href={href}
                  component={component}
                />
              </BoxComponent>
            ) : null}
          </BoxComponent>
        </BoxComponent>
      </BoxComponent>
    </BoxComponent>
  );
};

export default TableHeader;
