import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getSignInURL } from "../../Services/ApiConfig";
import axiosInstance from "../../Services/API";
import toast from "react-hot-toast";

const initialState = {
  user: [],
  isLoading: false,
  error: null,
};

/**
 * Login api
 */
export const login = createAsyncThunk("/auth/login", async (parameters) => {
  try {
    const response = await axiosInstance.post(getSignInURL(), parameters);
    if (response.status === 200) {
      toast.success("Login Successfully");
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.error);
  }
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isLoading = false;
        state.error = false;
      })
      .addCase(login.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
        state.user = [];
      });
  },
});

export const getLoadingState = (state) => state.auth.isLoading;

/**
 * export the reducer
 */
export default authSlice.reducer;
