import React, { useEffect, useState } from "react";
import { FormHelperText, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const MUIDatePicker = ({
  label,
  value,
  onChange,
  disabled,
  name,
  error,
  errorMessage,
  format,
  inputFormat,
}) => {
  const [formattedValue, setFormattedValue] = useState(new Date());

  useEffect(() => {
    if (value) {
      const formattedDate = dayjs(value).format("MM/DD/YYYY");
      setFormattedValue(formattedDate);
    }
  }, [value]);

  const handleDateChange = (newDate) => {
    const formattedDate = newDate
      ? dayjs(newDate, "MM/DD/YYYY").format("YYYY-MM-DD")
      : null;
    onChange(formattedDate);
    setFormattedValue(newDate);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        sx={{ width: "100%" }}
        format={format}
        label={label}
        name={name}
        value={dayjs(formattedValue)}
        onChange={handleDateChange}
        disabled={disabled}
        inputFormat={inputFormat}
        textField={(props) => <TextField error={error} {...props} />}
      />
      <FormHelperText sx={{ color: "error.main", ml: 0 }}>
        {errorMessage}
      </FormHelperText>
    </LocalizationProvider>
  );
};
export default MUIDatePicker;
