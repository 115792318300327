import React from "react";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { UserActions } from "../../Utils/helper";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from "@mui/icons-material/Delete";

export const RowOptions = (props) => {
  const { selectionModel, handleUserAction, handleClickOpen } = props;

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Tooltip title="View">
        <IconButton
          size="small"
          onClick={() =>
            handleUserAction(selectionModel, UserActions.SHOW_DETAILS)
          }
        >
          <VisibilityIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Edit">
        <IconButton
          size="small"
          onClick={() => handleUserAction(selectionModel, UserActions.EDIT)}
        >
          <ModeEditIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton
          size="small"
          onClick={() => {
            handleUserAction(selectionModel, UserActions.DELETE);
            handleClickOpen();
          }}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

