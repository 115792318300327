import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../Pages/Login/Login";
import Member from "../Pages/Member/Member";
import MemberList from "../Pages/MemberList/MemberList";
import ProtectedRoute from "./ProtectedRoute";
import AllMemberList from "../Pages/AllMemberList/AllMemberList";
import Dashboard from "../Pages/Dashboard/Dashboard";
import PageNotFound from "../Components/PageNotFound/PageNotFound";
import Header from "../Components/Header/Header";
import AuthRoute from "./AuthRoute";

const RouteManager = () => {
  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<Header />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/add-member" element={<Member />} />
          <Route path="/show-member/:showId" element={<Member />} />
          <Route path="/edit-member/:editId" element={<Member />} />
          <Route path="/family-member-list" element={<MemberList />} />
          <Route path="/all-member-list" element={<AllMemberList />} />
        </Route>
      </Route>
      <Route element={<AuthRoute />}>
        <Route path="/signin" element={<Login />} />
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default RouteManager;
