import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createFamily,
  fetchSingleFamily,
  getSingleFamily,
  updateFamily,
} from "../../Redux/Reducer/FamilyReducer";
import InputText from "../../Common/InputText/InputText";
import MUICard from "../../Common/Card/Card";
import { useNavigate, useParams } from "react-router-dom";
import MUIDivider from "../../Common/Divider/Divider";
import MUIGrid from "../../Common/MuiGrid/MuiGrid";
import { isValidEmail, validateMobileNumber } from "../../Utils/helper";
import TextArea from "../../Common/TextArea/TextArea";
import { FormHelperText } from "@mui/material";
import MUIDatePicker from "../../Common/MUIDatePicker/MUIDatePicker";
import DeleteIcon from "@mui/icons-material/Delete";

const relationDropdown = [
  "Father",
  "Mother",
  "Sister",
  "Brother",
  "Grandfather",
  "Grandmother",
  "Uncle",
  "Aunt",
  "Cousin",
  "Nephew",
  "Niece",
  "Wife",
  "Husband",
  "Son",
  "Daughter",
  "Stepfather",
  "Stepmother",
  "Stepson",
  "Stepdaughter",
  "Half-brother",
  "Half-sister",
  "Father-in-law",
  "Mother-in-law",
  "Sister-in-law",
  "Brother-in-law",
  "Son-in-law",
  "Daughter-in-law",
  "Godfather",
  "Godmother",
  "Godson",
  "Goddaughter",
  "Foster father",
  "Foster mother",
  "Other"
];
const maritalDropdown = [
  "Single",
  "Married",
  "Widowed",
  "Divorced",
  "Separated",
  "Other",
];

const Member = () => {
  /**
   * hooks
   */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showId, editId } = useParams();
  const data = useSelector(getSingleFamily);
  const familyData = data?.family_member;
  const subFamilyData = data?.sub_family_members;

  /**
   * states
   */
  const [inputValues, setInputValues] = useState({
    fname: "",
    mname: "",
    lname: "",
    mobile_no: "",
    residential_address: "",
    permananent_address: "",
    adhaar_no: "",
    voter_no: "",
    gender: "",
    occupation: "",
    residential_pincode: "",
    permananent_pincode: "",
    birthdate: "",
    marital_status: "",
    marrige_date: "",
    email: "",
    age: "",
    sub_family_members: [],
  });

  const [age, setAge] = useState("");
  const [subFamMemberDate, setSubFamMemberDate] = useState("");
  const [showAddMember, setShowAddMember] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [showSubmitButton, setShowSubmitButton] = useState(!showId);
  const [selectedMaritalStatus, setSelectedMaritalStatus] = useState("");
  const [selectedSubMaritalStatus, setSelectedSubMaritalStatus] = useState("");
  const [showAddMemberBtn, setShowAddMemberBtn] = useState(true);

  /**
   * errors States
   */
  const [errors, setErrors] = useState({
    fname: "",
    mname: "",
    lname: "",
    mobile_no: "",
    residential_address: "",
    permananent_address: "",
    adhaar_no: "",
    voter_no: "",
    gender: "",
    occupation: "",
    residential_pincode: "",
    permananent_pincode: "",
    birthdate: "",
    marital_status: "",
    marrige_date: "",
    email: "",
    age: "",
    sub_family_members: Array.from({ length: inputValues.sub_family_members.length }, () => ({
      email: "",
      adhaar_no: "",
      voter_no: "",
      mobile_no: "",
    }))
  });

  /**
   * useEffect for fetch single
   */
  useEffect(() => {
    if (showId || editId) {
      dispatch(fetchSingleFamily(showId || editId));
    }
  }, [editId, showId]);

  /**
   * useEffect for member route text box empty
   */
  useEffect(() => {
    if (!showId && !editId) {
      const newInputValues = {
        fname: "",
        mname: "",
        lname: "",
        mobile_no: "",
        residential_address: "",
        permananent_address: "",
        adhaar_no: "",
        voter_no: "",
        gender: "",
        occupation: "",
        residential_pincode: "",
        permananent_pincode: "",
        birthdate: "",
        marital_status: "",
        marrige_date: "",
        email: "",
        age: "",
        sub_family_members: [],
      };
      setInputValues(newInputValues);
      setShowSubmitButton(true);
    }
  }, [showId, editId]);

  /**
   * validation
   * @param {*} name
   * @param {*} value
   * @returns
   */
  const validateForm = (name, value) => {
    switch (name) {
      case "fname":
        if (!value) {
          return "First name is required";
        }
        return "";
      case "lname":
        if (!value) {
          return "Last name is required";
        }
        return "";
      case "mobile_no":
        if (!value) {
          return "Mobile number is required";
        } else {
          const mobileValidationMessage = validateMobileNumber(value);
          if (mobileValidationMessage) {
            return mobileValidationMessage;
          }
        }
        return "";
      case "residential_address":
        if (!value) {
          return "Residential address is required";
        }
        return "";
      case "permananent_address":
        if (!value) {
          return "Permanent address is required";
        }
        return "";
      case "email":
        const emailValidationMessage = isValidEmail(value);
        if (emailValidationMessage) {
          return emailValidationMessage;
        }
        return "";
      case "occupation":
        if (!value) {
          return "Occupation is required";
        }
        return "";
      case "residential_pincode":
        if (!value) {
          return "PIN code is required";
        } else if (isNaN(value)) {
          return "PIN code must be a number";
        } else if (parseInt(value) <= 0) {
          return "PIN code must be a positive number";
        }
        return "";
      case "permananent_pincode":
        if (!value) {
          return "PIN code is required";
        } else if (isNaN(value)) {
          return "PIN code must be a number";
        } else if (parseInt(value) <= 0) {
          return "PIN code must be a positive number";
        }
        return "";
      case "birthdate":
        if (!value) {
          return "Select date of birth";
        }
        return "";
      case "marital_status":
        if (!value) {
          return "Select marital status";
        }
        return "";
      case "adhaar_no":
        var aadhaarRegex = /^\d{12}$/;
        if (value && !aadhaarRegex.test(value)) {
          return "Enter valid aadhaar number";
        }
        return "";
      case "voter_no":
        var voterIDRegex = /^[A-Z]{3}[0-9]{7}$/;
        if (value && !voterIDRegex.test(value)) {
          return "Enter valid voter ID";
        }
        return "";
      case "gender":
        if (!value) {
          return "Gender is required";
        }
        return "";
      default:
        return "";
    }
  };

  /**
   * validation for subFamily member
   */
  const validateSubmemberForm = (name, value) => {
    switch (name) {
      case "email":
        var emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
        if (value && !emailRegex.test(value)) {
          return "Enter valid email";
        }
        return "";
      case "mobile_no":
        var mobileRegex = /^[789]\d{9}$/;
        if (value && !mobileRegex.test(value)) {
          return "Enter a valid 10-digit mobile number starting with 7, 8, or 9";
        }
        return "";
      case "adhaar_no":
        var aadhaarRegex = /^\d{12}$/;
        if (value && !aadhaarRegex.test(value)) {
          return "Enter valid aadhaar number";
        }
        return "";
      case "voter_no":
        var voterIDRegex = /^[A-Z]{3}[0-9]{7}$/;
        if (value && !voterIDRegex.test(value)) {
          return "Enter valid voter ID";
        }
        return "";
      default:
        return "";
    }
  };

  /**
   * onchange event
   */
  const handleOnChange = (event) => {
    const { name, value } = event.target;

    let formattedValue = value;

    if (name === "voter_no") {
      formattedValue = value.substring(0, 3).toUpperCase() + value.substring(3);
    } else if (name === "fname" || name === "lname" || name === "mname" || name === "residential_address" || name === "permananent_address" || name === "occupation") {
      formattedValue = value.charAt(0).toUpperCase() + value.slice(1);
    }

    setInputValues({ ...inputValues, [name]: formattedValue });
    setErrors({ ...errors, [name]: validateForm(name, formattedValue) });
  };

  /**
   * handle delete main member
   */
  const handleDeleteMember = () => {
    setShowAddMemberBtn(true);
    setInputValues({
      fname: "",
      mname: "",
      lname: "",
      mobile_no: "",
      residential_address: "",
      permananent_address: "",
      adhaar_no: "",
      voter_no: "",
      gender: "",
      occupation: "",
      residential_pincode: "",
      permananent_pincode: "",
      birthdate: "",
      marital_status: "",
      marrige_date: "",
      email: "",
      age: "",
      sub_family_members: inputValues.sub_family_members || [],
    });
  };

  /**
   * handle delete sub member
   */
  const handleSubDeleteMember = (indexToDelete) => {
    const updatedSubFamilyMembers = [...inputValues.sub_family_members];

    updatedSubFamilyMembers.splice(indexToDelete, 1);

    setInputValues({
      ...inputValues,
      sub_family_members: updatedSubFamilyMembers,
    });

    if (updatedSubFamilyMembers.length === 0) {
      setShowAddMemberBtn(true);
    }
  };

  /**
   * handle add sub family member
   */
  const handleAddMember = () => {
    setShowAddMemberBtn(false);
    setShowAddMember(true);
    setInputValues({
      ...inputValues,
      sub_family_members: [
        ...inputValues.sub_family_members,
        {
          fname: "",
          mname: "",
          lname: "",
          adhaar_no: "",
          voter_no: "",
          marital_status: "",
          marrige_date: "",
          mobile_no: "",
          gender: "",
          email: "",
          age: "",
          relation: "",
          birthdate: "",
          occupation: "",
        },
      ],
    });
  };

  /**
   * handle member change
   * @param {*} index
   * @param {*} event
   */
  const handleMemberChange = (index, event) => {
    const { name, value } = event.target;

    let formattedValue = value;
    if (name === "voter_no") {
      formattedValue = value.substring(0, 3).toUpperCase() + value.substring(3);
    } else if (name === "fname" || name === "lname" || name === "mname" || name === "occupation") {
      formattedValue = value.charAt(0).toUpperCase() + value.slice(1);
    }
    const updatedMembers = inputValues.sub_family_members.map((member, i) => {
      if (i === index) {
        return { ...member, [name]: formattedValue };
      }
      return member;
    });

    setInputValues({
      ...inputValues,
      sub_family_members: updatedMembers,
    });

    setErrors(prevErrors => ({
      ...prevErrors,
      sub_family_members: [
        ...prevErrors.sub_family_members.slice(0, index),
        {
          ...prevErrors.sub_family_members[index],
          [name]: validateSubmemberForm(name, value)
        },
        ...prevErrors.sub_family_members.slice(index + 1)
      ]
    }));

  };

  /**
   * handle date picker
   * @param {*} index
   * @param {*} newDate
   */
  const handleBirthDateChange = (newStartDate) => {
    /**
     * age calculator
     */
    const birthdateObj = new Date(newStartDate);
    const now = new Date();
    let ageDiff = now.getFullYear() - birthdateObj.getFullYear();
    const monthDiff = now.getMonth() - birthdateObj.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && now.getDate() < birthdateObj.getDate())
    ) {
      ageDiff--;
    }

    setInputValues({
      ...inputValues,
      birthdate: newStartDate,
      age: ageDiff,
    });
    setErrors({
      ...errors,
      birthdate: "",
    });
  };

  const handleMarriageDateChange = (newStartDate) => {
    setInputValues({
      ...inputValues,
      marrige_date: newStartDate,
    });
  };

  const handleSubMarriageDateChange = (index, newDate) => {
    const updatedMembers = inputValues.sub_family_members.map((member, i) => {
      if (i === index) {
        return { ...member, marrige_date: newDate };
      }
      return member;
    });
    setInputValues({
      ...inputValues,
      sub_family_members: updatedMembers,
    });
  };

  const handleSubBirthDateChange = (index, newDate) => {
    /**
     * Age Calculator
     */
    const birthdateObj = new Date(newDate);
    const now = new Date();
    let ageDiff = now.getFullYear() - birthdateObj.getFullYear();
    const monthDiff = now.getMonth() - birthdateObj.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && now.getDate() < birthdateObj.getDate())
    ) {
      ageDiff--;
    }

    const updatedMembers = inputValues.sub_family_members.map((member, i) => {
      if (i === index) {
        return { ...member, birthdate: newDate, age: ageDiff };
      }
      return member;
    });
    setInputValues({
      ...inputValues,
      sub_family_members: updatedMembers,
    });
  };

  /**
   * useEffect
   */
  useEffect(() => {
    if (showId || editId) {
      const newInputValues = {
        fname: familyData?.fname || "",
        mname: familyData?.mname || "",
        lname: familyData?.lname || "",
        mobile_no: familyData?.mobile_no || "",
        residential_address: familyData?.residential_address || "",
        permananent_address: familyData?.permananent_address || "",
        adhaar_no: familyData?.adhaar_no || "",
        voter_no: familyData?.voter_no || "",
        gender: familyData?.gender || "",
        occupation: familyData?.occupation || "",
        residential_pincode: familyData?.residential_pincode || "",
        permananent_pincode: familyData?.permananent_pincode || "",
        birthdate: familyData?.birthdate || "",
        marital_status: familyData?.marital_status || "",
        marrige_date: familyData?.marrige_date || "",
        email: familyData?.email || "",
        age: familyData?.age || "",
        sub_family_members: subFamilyData || [],
      };
      setInputValues(newInputValues);
      setSelectedMaritalStatus(newInputValues?.marital_status);
      setSelectedSubMaritalStatus(newInputValues?.marital_status);
    }

    if (showId) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [familyData, showId, editId]);

  useEffect(() => {
    if (age) {
      const newInputValues = {
        ...inputValues,
        age: age,
      };
      setInputValues(newInputValues);
      setErrors({ ...errors, age: "" });
    }
  }, [age]);

  useEffect(() => {
    if (subFamMemberDate) {
      setErrors({ ...errors, age: "" });
    }
  }, [subFamMemberDate]);

  /**
   * handleSubmit
   */
  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = {};
    Object.keys(inputValues).forEach((name) => {
      const error = validateForm(name, inputValues[name]);
      if (error && error.length > 0) {
        validationErrors[name] = error;
      }
    });
    if (Object.keys(validationErrors).length > 0) {
      setErrors({ ...errors, ...validationErrors });
      return;
    }

    let parameters;

    if (editId) {
      parameters = {
        id: editId,
        formValues: { ...inputValues },
      };
    } else {
      parameters = {
        id: showId,
        formValues: { ...inputValues },
      };
    }

    if (editId || showId) {
      dispatch(updateFamily({ parameters })).then((res) => {
        if (res?.payload?.status === 201) {
          navigate("/family-member-list");
        }
      });
    } else {
      dispatch(createFamily(inputValues)).then((res) => {
        if (res?.payload?.status === 201) {
          navigate("/family-member-list");
        }
      });
    }
  };

  /**
   * handle edit
   */
  const handleEdit = () => {
    setIsDisabled(false);
    setShowSubmitButton(true);
  };

  return (
    <Box sx={{ p: 2 }}>
      <MUICard variant="outlined">
        <form onSubmit={handleSubmit} autoComplete="off">
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5" sx={{ m: "2%" }}>
              {showId
                ? "Family Details"
                : editId
                  ? "Update Family Details"
                  : "Add Family Details"}
            </Typography>
            {showId ? (
              <Button
                variant="contained"
                onClick={handleEdit}
                sx={{ width: "100px", height: "40px", m: 2 }}
              >
                Edit
              </Button>
            ) : null}
          </Box>
          <MUIDivider />
          <MUIGrid container spacing={2} sx={{ p: 2 }}>
            <MUIGrid item xs={12} sm={4}>
              <InputText
                required
                size="medium"
                label="First Name"
                name="fname"
                type="text"
                variant="outlined"
                placeholder="First Name"
                id="fname"
                onChange={handleOnChange}
                value={inputValues.fname}
                disabled={isDisabled}
                error={errors.fname ? true : false}
                errorMessage={errors.fname}
              />
            </MUIGrid>
            <MUIGrid item xs={12} sm={4}>
              <InputText
                size="medium"
                label="Middle Name"
                name="mname"
                type="text"
                variant="outlined"
                placeholder="Middle Name"
                id="mname"
                onChange={handleOnChange}
                value={inputValues.mname}
                disabled={isDisabled}
                error={errors.mname ? true : false}
                errorMessage={errors.mname}
              />
            </MUIGrid>
            <MUIGrid item xs={12} sm={4}>
              <InputText
                required
                size="medium"
                label="Last Name"
                name="lname"
                type="text"
                variant="outlined"
                placeholder="Last Name"
                id="lname"
                onChange={handleOnChange}
                value={inputValues.lname}
                disabled={isDisabled}
                error={errors.lname ? true : false}
                errorMessage={errors.lname}
              />
            </MUIGrid>
            <MUIGrid item xs={12} sm={6}>
              <InputText
                required
                size="medium"
                label="Mobile Number"
                name="mobile_no"
                type="text"
                variant="outlined"
                placeholder="Mobile Number"
                id="mobile_no"
                onChange={handleOnChange}
                value={inputValues.mobile_no}
                disabled={isDisabled}
                error={errors.mobile_no ? true : false}
                errorMessage={errors.mobile_no}
              />
            </MUIGrid>
            <MUIGrid item xs={12} sm={6}>
              <InputText
                required
                size="medium"
                label="Email"
                name="email"
                type="text"
                variant="outlined"
                placeholder="Email"
                id="email"
                onChange={handleOnChange}
                value={inputValues.email}
                disabled={isDisabled}
                error={errors.email ? true : false}
                errorMessage={errors.email}
              />
            </MUIGrid>
            <MUIGrid item xs={12} sm={12} sx={{ textAlign: "start", mt: "-10px" }}>
              <FormLabel required id="demo-radio-buttons-group-label">Gender</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                name="gender"
                value={inputValues.gender}
                onChange={handleOnChange}
              >
                <FormControlLabel
                  disabled={isDisabled}
                  value="Male"
                  control={<Radio />}
                  label="Male"
                  tabIndex={1}
                  sx={{ '&:focus': { outline: 'none' } }}
                />
                <FormControlLabel
                  disabled={isDisabled}
                  value="Female"
                  control={<Radio />}
                  label="Female"
                  tabIndex={2}
                  sx={{ '&:focus': { outline: 'none' } }}
                />
                <FormControlLabel
                  disabled={isDisabled}
                  value="Other"
                  control={<Radio />}
                  label="Other"
                  tabIndex={3}
                  sx={{ '&:focus': { outline: 'none' } }}
                />
              </RadioGroup>
              <FormHelperText sx={{ color: "error.main", ml: 0 }}>
                {errors.gender}
              </FormHelperText>
            </MUIGrid>
            <MUIGrid item xs={12} sm={12}>
              <TextArea
                required
                size="medium"
                label="Residential Address"
                name="residential_address"
                type="text"
                variant="outlined"
                placeholder="Residential Address"
                id="residential_address"
                onChange={handleOnChange}
                value={inputValues.residential_address}
                disabled={isDisabled}
                error={errors.residential_address ? true : false}
                errorMessage={errors.residential_address}
              />
            </MUIGrid>
            <MUIGrid item xs={12} sm={12}>
              <InputText
                required
                size="medium"
                label="Residential Pin Code"
                name="residential_pincode"
                type="text"
                variant="outlined"
                placeholder="Residential Pin Code"
                id="residential_pincode"
                onChange={handleOnChange}
                value={inputValues.residential_pincode}
                disabled={isDisabled}
                error={errors.residential_pincode ? true : false}
                errorMessage={errors.residential_pincode}
              />
            </MUIGrid>
            <MUIGrid item xs={12} sm={12}>
              <TextArea
                required
                size="medium"
                label="Permanent Address"
                name="permananent_address"
                type="text"
                variant="outlined"
                placeholder="Permanent Address"
                id="permananent_address"
                onChange={handleOnChange}
                value={inputValues.permananent_address}
                disabled={isDisabled}
                error={errors.permananent_address ? true : false}
                errorMessage={errors.permananent_address}
              />
            </MUIGrid>
            <MUIGrid item xs={12} sm={12}>
              <InputText
                required
                size="medium"
                label="Permanent Pin Code"
                name="permananent_pincode"
                type="text"
                variant="outlined"
                placeholder="Permanent Pin Code"
                id="permananent_pincode"
                onChange={handleOnChange}
                value={inputValues.permananent_pincode}
                disabled={isDisabled}
                error={errors.permananent_pincode ? true : false}
                errorMessage={errors.permananent_pincode}
              />
            </MUIGrid>
            <MUIGrid item xs={12} sm={6}>
              <InputText
                required
                size="medium"
                label="Occupation"
                name="occupation"
                type="text"
                variant="outlined"
                placeholder="Occupation"
                id="occupation"
                onChange={handleOnChange}
                value={inputValues.occupation}
                disabled={isDisabled}
                error={errors.occupation ? true : false}
                errorMessage={errors.occupation}
              />
            </MUIGrid>
            <MUIGrid item xs={12} sm={6}>
              <InputText
                size="medium"
                label="Aadhaar Number"
                name="adhaar_no"
                type="text"
                variant="outlined"
                placeholder="Aadhaar Number"
                id="adhaar_no"
                onChange={handleOnChange}
                value={inputValues.adhaar_no}
                disabled={isDisabled}
                error={errors.adhaar_no ? true : false}
                errorMessage={errors.adhaar_no}
              />
            </MUIGrid>
            <MUIGrid item xs={12} sm={6}>
              <InputText
                size="medium"
                label="Voter ID"
                name="voter_no"
                type="text"
                variant="outlined"
                placeholder="Voter ID"
                id="voter_no"
                onChange={handleOnChange}
                value={inputValues.voter_no}
                disabled={isDisabled}
                error={errors.voter_no ? true : false}
                errorMessage={errors.voter_no}
              />
            </MUIGrid>
            <MUIGrid item xs={12} sm={6}>
              <MUIDatePicker
                label="Birth Date"
                name="birthdate"
                value={inputValues.birthdate}
                onChange={handleBirthDateChange}
                error={errors.birthdate ? true : false}
                errorMessage={errors.birthdate ? errors.birthdate : ""}
                disabled={isDisabled}
                inputFormat="yyyy-MM-dd"
                format="DD/MM/YYYY"
              />
            </MUIGrid>
            <MUIGrid item xs={12} sm={6} sx={{ mt: { xs: "16px", sm: "0px" } }}>
              <InputText
                required
                size="medium"
                label="Age"
                name="age"
                type="text"
                variant="outlined"
                placeholder="Age"
                id="age"
                onChange={handleOnChange}
                value={inputValues.age}
                disabled={isDisabled}
                error={errors.age ? true : false}
                errorMessage={errors.age}
              />
            </MUIGrid>
            <MUIGrid item xs={12} sm={6}>
              <Autocomplete
                disabled={isDisabled}
                disablePortal
                options={maritalDropdown}
                value={inputValues.marital_status}
                onChange={(event, newValue) => {
                  handleOnChange({
                    target: { name: "marital_status", value: newValue },
                  });
                  setSelectedMaritalStatus(newValue);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Marital Status"
                    name="marital_status"
                    required
                    error={errors.marital_status ? true : false}
                  />
                )}
              />
              <FormHelperText sx={{ color: "error.main", ml: 0 }}>
                {errors.marital_status}
              </FormHelperText>
            </MUIGrid>

            {selectedMaritalStatus === "Married" && (
              <MUIGrid item xs={12} sm={6} sx={{ mt: { xs: "16px", sm: "0px" } }}>
                <MUIDatePicker
                  label="Anniversary Date"
                  name="marrige_date"
                  value={inputValues.marrige_date}
                  onChange={handleMarriageDateChange}
                  error={errors.marrige_date ? true : false}
                  errorMessage={errors.marrige_date}
                  disabled={isDisabled}
                  format="DD/MM/YYYY"
                />
              </MUIGrid>
            )}
          </MUIGrid>
          <MUIDivider />

          {showSubmitButton && (
            <MUIGrid item xs={12} sm={12} sx={{ textAlign: "end", m: 1 }}>
              {inputValues?.sub_family_members.length === 0 && (
                <Button
                  variant="outlined"
                  onClick={handleAddMember}
                  sx={{ textTransform: "none", height: "40px" }}
                >
                  Add Member
                </Button>
              )}
              <IconButton size="small" onClick={handleDeleteMember}>
                <DeleteIcon style={{ color: "red", marginTop: "5px 30px" }} />
              </IconButton>
            </MUIGrid>
          )}

          {/*SUB FAMILY*/}
          {showId || editId || showAddMember ? (
            <>
              {inputValues?.sub_family_members?.map((member, index) => (
                <Box key={index}>
                  <MUIDivider />
                  <Typography variant="h5" sx={{ m: "2%" }}>
                    Sub Family Member
                  </Typography>
                  <MUIDivider />
                  <MUIGrid container spacing={2} sx={{ p: 2 }}>
                    <MUIGrid item xs={12} sm={4}>
                      <InputText
                        size="medium"
                        label="First Name"
                        name="fname"
                        type="text"
                        variant="outlined"
                        placeholder="First Name"
                        id="fname"
                        value={member.fname}
                        disabled={isDisabled}
                        onChange={(event) => handleMemberChange(index, event)}
                      />
                    </MUIGrid>
                    <MUIGrid item xs={12} sm={4}>
                      <InputText
                        size="medium"
                        label="Middle Name"
                        name="mname"
                        type="text"
                        variant="outlined"
                        placeholder="Middle Name"
                        id="mname"
                        value={member.mname}
                        disabled={isDisabled}
                        onChange={(event) => handleMemberChange(index, event)}
                      />
                    </MUIGrid>
                    <MUIGrid item xs={12} sm={4}>
                      <InputText
                        size="medium"
                        label="Last Name"
                        name="lname"
                        type="text"
                        variant="outlined"
                        placeholder="Last Name"
                        id="lname"
                        value={member.lname}
                        onChange={(event) => handleMemberChange(index, event)}
                        disabled={isDisabled}
                      />
                    </MUIGrid>
                    <MUIGrid item xs={12} sm={6}>
                      <InputText
                        size="medium"
                        label="Mobile Number"
                        name="mobile_no"
                        type="text"
                        variant="outlined"
                        placeholder="Mobile Number"
                        id="mobile_no"
                        value={member.mobile_no}
                        onChange={(event) => handleMemberChange(index, event)}
                        disabled={isDisabled}
                        error={errors.sub_family_members[index]?.mobile_no ? true : false}
                        errorMessage={errors.sub_family_members[index]?.mobile_no}
                      />
                    </MUIGrid>
                    <MUIGrid item xs={12} sm={6}>
                      <InputText
                        size="medium"
                        label="Email"
                        name="email"
                        type="email"
                        variant="outlined"
                        placeholder="Email"
                        id="email"
                        value={member.email}
                        onChange={(event) => handleMemberChange(index, event)}
                        disabled={isDisabled}
                        error={errors.sub_family_members[index]?.email ? true : false}
                        errorMessage={errors.sub_family_members[index]?.email}
                      />
                    </MUIGrid>
                    <MUIGrid item xs={12} sm={12} sx={{ textAlign: "start" ,mt: "-10px"  }}>
                      <FormLabel id="demo-radio-buttons-group-label">
                        Gender
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby={`demo-radio-buttons-group-label-${index}`}
                        name={`gender-${index}`}
                        value={member.gender}
                        onChange={(event) => handleMemberChange(index, { target: { name: "gender", value: event.target.value } })}
                      >
                        <FormControlLabel
                          disabled={isDisabled}
                          value="Male"
                          control={<Radio />}
                          label="Male"
                          tabIndex={1}
                          sx={{ '&:focus': { outline: 'none' } }}
                        />
                        <FormControlLabel
                          disabled={isDisabled}
                          value="Female"
                          control={<Radio />}
                          label="Female"
                          tabIndex={2}
                          sx={{ '&:focus': { outline: 'none' } }}
                        />
                        <FormControlLabel
                          disabled={isDisabled}
                          value="Other"
                          control={<Radio />}
                          label="Other"
                          tabIndex={3}
                          sx={{ '&:focus': { outline: 'none' } }}
                        />
                      </RadioGroup>
                    </MUIGrid>
                    <MUIGrid item xs={12} sm={6}>
                      <InputText
                        size="medium"
                        label="Aadhaar Number"
                        name="adhaar_no"
                        type="text"
                        variant="outlined"
                        placeholder="Aadhaar Number"
                        id="adhaar_no"
                        value={member.adhaar_no}
                        onChange={(event) => handleMemberChange(index, event)}
                        disabled={isDisabled}
                        error={errors.sub_family_members[index]?.adhaar_no ? true : false}
                        errorMessage={errors.sub_family_members[index]?.adhaar_no}
                      />
                    </MUIGrid>
                    <MUIGrid item xs={12} sm={6}>
                      <InputText
                        size="medium"
                        label="Voter ID"
                        name="voter_no"
                        type="text"
                        variant="outlined"
                        placeholder="Voter ID"
                        id="voter_no"
                        value={member.voter_no}
                        onChange={(event) => handleMemberChange(index, event)}
                        disabled={isDisabled}
                        error={errors.sub_family_members[index]?.voter_no ? true : false}
                        errorMessage={errors.sub_family_members[index]?.voter_no}
                      />
                    </MUIGrid>
                    <MUIGrid item xs={12} sm={6}>
                      <MUIDatePicker
                        label="Birth Date"
                        name="birthdate"
                        value={member.birthdate}
                        onChange={(newDate) =>
                          handleSubBirthDateChange(index, newDate)
                        }
                        disabled={isDisabled}
                        format="DD/MM/YYYY"
                      />
                    </MUIGrid>
                    <MUIGrid item xs={12} sm={6} sx={{ mt: { xs: "16px", sm: "0px" } }}>
                      <InputText
                        size="medium"
                        label="Age"
                        name="age"
                        type="text"
                        variant="outlined"
                        placeholder="Age"
                        id="age"
                        value={member.age}
                        onChange={(event) => handleMemberChange(index, event)}
                        disabled={isDisabled}
                      />
                    </MUIGrid>
                    <MUIGrid item xs={12} sm={6}>
                      <InputText
                        size="medium"
                        label="Occupation"
                        name="occupation"
                        type="text"
                        variant="outlined"
                        placeholder="Occupation"
                        id="occupation"
                        onChange={(event) => handleMemberChange(index, event)}
                        value={member.occupation}
                        disabled={isDisabled}
                      />
                    </MUIGrid>
                    <MUIGrid item xs={12} sm={6} sx={{ mb: { xs: "16px", sm: "0px" } }}>
                      <Autocomplete
                        disabled={isDisabled}
                        disablePortal
                        id={`combo-box-demo-${index}`}
                        options={maritalDropdown}
                        value={member.marital_status}
                        onChange={(event, newValue) => {
                          handleMemberChange(index, {
                            target: { name: "marital_status", value: newValue },
                          });
                          setSelectedSubMaritalStatus(newValue);
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="Marital Status"
                            name="marital_status"
                          />
                        )}
                      />
                    </MUIGrid>

                    {member.marital_status === "Married" && (
                      <MUIGrid item xs={12} sm={6} sx={{ mb: { xs: "16px", sm: "0px" } }}>
                        <MUIDatePicker
                          label="Anniversary Date"
                          name="marrige_date"
                          value={member.marrige_date}
                          onChange={(newDate) =>
                            handleSubMarriageDateChange(index, newDate)
                          }
                          disabled={isDisabled}
                          format="DD/MM/YYYY"
                        />
                      </MUIGrid>
                    )}

                    <MUIGrid item xs={12} sm={6}>
                      <Autocomplete
                        disabled={isDisabled}
                        disablePortal
                        id={`combo-box-demo-${index}`}
                        options={relationDropdown}
                        value={member.relation}
                        onChange={(event, newValue) =>
                          handleMemberChange(index, {
                            target: { name: "relation", value: newValue },
                          })
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                            label="Relation"
                            name="relation"
                          />
                        )}
                      />
                    </MUIGrid>
                  </MUIGrid>

                  {showSubmitButton && (
                    <MUIGrid
                      item
                      xs={12}
                      sm={12}
                      sx={{ textAlign: "end", m: 1 }}
                    >
                      {index === inputValues.sub_family_members.length - 1 ? (
                        <Button
                          variant="outlined"
                          onClick={handleAddMember}
                          sx={{ textTransform: "none", height: "40px" }}
                        >
                          Add Member
                        </Button>
                      ) : null}
                      <IconButton
                        size="small"
                        onClick={() => handleSubDeleteMember(index)}
                      >
                        <DeleteIcon
                          style={{ color: "red", marginTop: "5px 30px" }}
                        />
                      </IconButton>
                    </MUIGrid>
                  )}
                </Box>
              ))}
              <MUIDivider />
            </>
          ) : null}
          <MUIDivider />

          {showSubmitButton && (
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Button
                type="submit"
                onClick={handleSubmit}
                variant="contained"
                sx={{
                  my: 2,
                  textTransform: "none",
                  width: "100px",
                  mt: "3%",
                }}
              >
                {editId || showId ? "Update" : "Submit"}
              </Button>
            </Grid>
          )}
        </form>
      </MUICard>
    </Box>
  );
};

export default Member;
