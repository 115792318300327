import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Button,
  Divider,
  Grid,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import MuiDrawer from "@mui/material/Drawer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import List from "@mui/material/List";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import GroupsIcon from "@mui/icons-material/Groups";
import DashboardIcon from "@mui/icons-material/Dashboard";

const pages = [
  {
    title: "Dashboard",
    icon: <DashboardIcon sx={{ color: "white" }} />,
    route: "/dashboard",
  },
  {
    title: "Add Member",
    icon: <PersonAddAlt1Icon sx={{ color: "white" }} />,
    route: "/add-member",
  },
  {
    title: "All Member List",
    icon: <GroupsIcon sx={{ color: "white" }} />,
    route: "/all-member-list",
  },
  {
    title: "Family Member List",
    icon: <GroupsIcon sx={{ color: "white" }} />,
    route: "/family-member-list",
  },
];

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

export default function Header() {
  /**
   * hooks
   */
  const navigate = useNavigate();
  const location = useLocation();
  const isXsScreen = useMediaQuery("(max-width: 960px)");
  const isLGScreen = useMediaQuery("(min-width: 960px)");

  /**
   * states
   */
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (isXsScreen) {
      setOpen(false);
    } else if (isLGScreen) {
      setOpen(true);
    }
  }, [isXsScreen, isLGScreen]);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleListItemClick = (drawerItem) => {
    if (isXsScreen) {
      setOpen(false);
    }
    navigate(drawerItem.route);
  };

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/signin");
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="absolute"
        open={open}
        sx={{ backgroundColor: "white", boxShadow: "none" }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <IconButton
            edge="start"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon style={{ color: "black" }} />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            noWrap
            sx={{ flexGrow: 1, color: "black" }}
          >
            Survey
          </Typography>
          <Button
            onClick={logout}
            variant="outlined"
            startIcon={<LogoutIcon />}
          >
            Logout
          </Button>
        </Toolbar>
        <Divider />
      </AppBar>
      <Box sx={{ backgroundColor: "#9614d0" }}>
        <Drawer
          variant="permanent"
          open={open}
          sx={{
            [`& .MuiDrawer-paper`]: {
              width: open ? drawerWidth : 0,
              boxSizing: "border-box",
              overflow: "hidden",
              backgroundColor: "#9614d0",
            },
          }}
        >
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton
              sx={{ color: "white", opacity: "0.5" }}
              onClick={handleDrawerOpen}
            >
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List sx={{ paddingTop: "0px" }}>
            {pages.map((item) => (
              <ListItem
                key={item.title}
                sx={{
                  display: "block",
                  py: 0,
                  px: 0,
                  backgroundColor:
                    location.pathname === item.route ? "#00000033" : "#9614d0",
                  color: "rgb(238 238 238 / 70%)",
                }}
                onClick={() => handleListItemClick(item)}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      mr: open ? 1 : "auto",
                      justifyContent: "center",
                      "& .MuiSvgIcon-root": {
                        width: "1.5rem",
                        height: "1.5rem",
                      },
                      color: "white",
                      opacity: "0.5",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography sx={{ fontSize: "16px", color: "white" }}>
                        {item.title}
                      </Typography>
                    }
                    style={{
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <Outlet />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
