import React from "react";
import TextField from "@mui/material/TextField";

const TextFieldComponent = (props) => {
  const { label, placeholder, sx, size, value, onChange } = props;
  return (
    <TextField
      fullWidth
      autoComplete="off"
      sx={sx}
      size={size}
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  );
};

export default TextFieldComponent;
