import React from "react";
import { Divider, Grid } from "@mui/material";

const MUIDivider = (props) => {
  const { title, sx } = props;
  return (
    <Grid item xs={12}>
      <Divider sx={sx}>{title}</Divider>
    </Grid>
  );
};

export default MUIDivider;
