import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router";
import { getAccessToken } from "../../Utils/helper";
import { Button } from "@mui/material";

// ** Styled Components
const BoxWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "90vw",
  },
}));

const PageNotFound = () => {
  const navigate = useNavigate();

  /**
   * get token in local storage
   */
  const token = getAccessToken();

  const handleNavigate = () => {
    navigate(token ? "/dashboard" : "/signin");
  };

  return (
    <Box
      className="content-center"
      sx={{
        height: "80vh",
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          width: "100%",
        }}
      >
        <BoxWrapper>
          <Typography variant="h1" sx={{ mb: 2.5 }}>
            404
          </Typography>
          <Typography
            variant="h5"
            sx={{
              mb: 2.5,
              letterSpacing: "0.18px",
              fontSize: "1.5rem !important",
            }}
          >
            Page Not Found ⚠️
          </Typography>
          <Typography variant="body2">
            We couldn't find the page you are looking for.
          </Typography>
        </BoxWrapper>
        <Button
          variant="contained"
          sx={{
            px: 5.5,
            mt: 3,
            borderRadius: "30px",
            textTransform: "capitalize",
          }}
          onClick={handleNavigate}
        >
          {token ? "Back to Dashboard" : "Back to Signin"}
        </Button>
      </Box>
    </Box>
  );
};

export default PageNotFound;
