import React, { useState } from "react";
import { Box, CircularProgress } from "@mui/material";

function withLoader(WrappedComponent) {
  return function WihLoadingComponent(props) {
    /**
     * State
     */
    const [isLoading, setLoading] = useState(false);

    const setLoadingState = (isComponentLoading) => {
      setLoading(isComponentLoading);
    };
    return (
      <>
        {isLoading && (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              position: "fixed",
              top: 0,
              left: 0,
              backgroundColor: "#0000001f",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 2000,
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <WrappedComponent {...props} setLoading={setLoadingState} />
      </>
    );
  };
}

export default withLoader;
