import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Services/API";
import { getAllFamilyListURL } from "../../Services/ApiConfig";

const initialState = {
  allFamilyList: [],
  isLoading: false,
  error: null,
};

/**
 * get all family list
 */
export const fetchAllFamilyList = createAsyncThunk(
  "/allFamilyList/view",
  async ({ page, per_Page, filters, filter }) => {
    try {
      const response = await axiosInstance.get(getAllFamilyListURL(filter), {
        params: {
          page: page,
          per_page: per_Page,
          filters: filters,
        },
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }
);

/**
 * create slice
 */
const familyAllListSlice = createSlice({
  name: "viewAllFamily",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllFamilyList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllFamilyList.fulfilled, (state, action) => {
        state.allFamilyList = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchAllFamilyList.rejected, (state) => {
        state.isLoading = false;
        state.allFamilyList = [];
      });
  },
});

export const getAllFamilyMemberList = (state) =>
  state.viewAllFamily.allFamilyList;
export const getLoadingState = (state) => state.viewAllFamily.isLoading;

export default familyAllListSlice.reducer;
