import axios from "axios";
import { getAccessToken } from "../Utils/helper";
import toast from "react-hot-toast";

let axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SURVEY_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

/**
 * Passed API authorization
 */
axiosInstance.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * handle HTTP responses
 */
axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const token = getAccessToken();
    if (err?.response?.status === 401 && token) {
      localStorage.removeItem("token");
      window.location.replace("/signin");
      toast.error("Your session has expired. Please log in again to continue");
    }
    return Promise.reject(err);
  }
);

/**
 * created axiosObj
 */
const axiosObj = {
  get: axiosInstance.get,
  post: (url, data) => axiosInstance.post(url, data),
  put: (url, data) => axiosInstance.put(url, data),
  delete: axiosInstance.delete,
};

/**
 * exported axiosObj
 */
export default axiosObj;
