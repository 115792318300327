import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBirthdayAnniversaryList,
  getLoadingState,
  getbirthdayAnniversaryList,
} from "../../Redux/Reducer/BirthdayAnniversaryReducer";
import "../../Style/Style.css";
import withLoader from "../../Components/HOC/WithLoader";

function Dashboard(props) {
  /**
   * props
   */
  const { setLoading } = props;
  /**
   * hooks
   */
  const dispatch = useDispatch();

  /**
   * get loading state
   */
  const isLoading = useSelector(getLoadingState);

  /**
   * setLoading
   */
  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  /**
   * get wish data
   */
  const getWishData = useSelector(getbirthdayAnniversaryList);

  useEffect(() => {
    dispatch(fetchBirthdayAnniversaryList());
  }, []);

  /**
   * get todays date
   */
  const today = new Date();

  /**
   * Get the day, month, and year separately
   */
  const day = String(today.getDate()).padStart(2, "0");
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const year = today.getFullYear();

  /**
   * Combine them in the desired format
   */
  const formattedDate = `(${day}-${month}-${year})`;

  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      <Grid item xs={12}>
        <Typography
          component="h1"
          variant="h5"
          color="inherit"
          noWrap
          sx={{
            flexGrow: 1,
            color: "rgb(0 0 0 / 87%)",
            fontSize: "1rem",
          }}
        >
          Today's Birthdays {`${formattedDate}`}
        </Typography>
      </Grid>
      {getWishData?.data?.birthday?.map((item, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Box
            sx={{
              p: 2,
              border: "1px solid #808080",
              borderRadius: "10px",
              textAlign: "center",
              transition: "background-color 0.3s",
              "&:hover": {
                backgroundColor: "#f0f0f0",
                cursor: "pointer",
              },
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: "16px",
                fontWeight: "bold",
                textTransform: "capitalize",
              }}
            >
              {item?.fname} {item?.mname} {item?.lname}
            </Typography>
            <Typography variant="subtitle1" sx={{ fontSize: "14px" }}>
              {item?.mobile_no}
            </Typography>
            <Typography variant="subtitle1" sx={{ fontSize: "14px" }}>
              {item?.family_head}
            </Typography>
            <Typography variant="subtitle1" sx={{ fontSize: "14px" }}>
              {item?.email}
            </Typography>
          </Box>
        </Grid>
      ))}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="h5" sx={{ pt: 3, textAlign: "center" }}>
          {getWishData?.data?.birthday?.length === 0 && "Today is No Birthday"}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          component="h1"
          variant="h5"
          color="inherit"
          noWrap
          sx={{
            flexGrow: 1,
            color: "rgb(0 0 0 / 87%)",
            paddingTop: "30px",
            fontSize: "1rem",
          }}
        >
          Today's Anniversary {`${formattedDate}`}
        </Typography>
      </Grid>
      {getWishData?.data?.marriage?.map((item, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Box
            sx={{
              p: 2,
              border: "1px solid #808080",
              borderRadius: "10px",
              textAlign: "center",
              transition: "background-color 0.3s",
              "&:hover": {
                backgroundColor: "#f0f0f0",
                cursor: "pointer",
              },
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: "16px",
                fontWeight: "bold",
                textTransform: "capitalize",
              }}
            >
              {item?.fname} {item?.mname} {item?.lname}
            </Typography>
            <Typography variant="subtitle1" sx={{ fontSize: "14px" }}>
              {item?.mobile_no}
            </Typography>
            <Typography variant="subtitle1" sx={{ fontSize: "14px" }}>
              {item?.family_head}
            </Typography>
            <Typography variant="subtitle1" sx={{ fontSize: "14px" }}>
              {item?.email}
            </Typography>
          </Box>
        </Grid>
      ))}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="h5" sx={{ pt: 3, textAlign: "center" }}>
          {getWishData?.data?.marriage?.length === 0 &&
            "Today is No Marriage Anniversary"}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default withLoader(Dashboard);
