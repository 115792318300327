const baseURL = process.env.REACT_APP_SURVEY_BASE_URL;

/**
 * Auth API URL's
 * @returns
 */
export const getSignInURL = () => `${baseURL}/api/login`;

/**
 * Family API URL's
 */
export const getFamilyListURL = (filter) =>
  `${baseURL}/api/family/?filter=${filter}`;

export const getFamilyAddListURL = () =>
  `${baseURL}/api/family`;

export const getFamilyURL = (id) => `${baseURL}/api/family/${id}`;

export const getFamilyUpdateListURL = (id) => `${baseURL}/api/family/put/${id}`;

export const getFamilyDeleteListURL = (id) => `${baseURL}/api/family/delete/${id}`;

/**
 * All family API URL's
 * @param {*} filter 
 * @returns 
 */
export const getAllFamilyListURL = (filter) =>
  `${baseURL}/api/familylist/?filter=${filter}`;

/**
 * birthday and anniversary URL
 */
export const getBirthdayAnniversaryURL = () => `${baseURL}/api/wish`;
