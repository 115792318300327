import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  InputAdornment,
  Box,
  Grid,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import img from "../../Assets/Mahadeo_pawar.png";
import { purple } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";
import { getLoadingState, login } from "../../Redux/Reducer/AuthReducer";
import { isValidEmail, saveAccessToken } from "../../Utils/helper";
import { useNavigate } from "react-router-dom";
import MUIGrid from "../../Common/MuiGrid/MuiGrid";
import InputText from "../../Common/InputText/InputText";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import withLoader from "../../Components/HOC/WithLoader";
import "../../Style/Style.css";
import OutlinedInputPassword from "../../Common/OutlinedInputPassword/OutlinedInputPassword";
import LockIcon from "@mui/icons-material/Lock";

const defaultTheme = createTheme();

const Login = (props) => {
  /**
   * props
   */
  const { setLoading } = props;

  /**
   * hooks
   */
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /**
   * get loading state
   */
  const isLoading = useSelector(getLoadingState);

  /**
   * setLoading
   */
  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  const [rememberMe, setRememberMe] = useState(true);

  /**
   * states
   */
  const [inputValues, setInputValues] = useState({
    email: "",
    password: "",
  });

  /**
   * errors States
   */
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  /**
   * fetch email/password page load
   */
  useEffect(() => {
    const newInputValues = {
      email: localStorage.getItem("email"),
      password: localStorage.getItem("password"),
    };
    setInputValues(newInputValues);
  }, []);

  /**
   * validation
   * @param {*} name
   * @param {*} value
   * @returns
   */
  const validateForm = (name, value) => {
    switch (name) {
      case "email":
        const emailValidationMessage = isValidEmail(value);
        if (emailValidationMessage) {
          return emailValidationMessage;
        }
        return "";
      case "password":
        if (!value || value.trim() === "") {
          return "Password is required";
        }
        return "";
      default:
        return "";
    }
  };

  /**
   * onchange event
   */
  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
    setErrors({ ...errors, [name]: validateForm(name, value) });
  };

  /**
   * handleSubmit
   */
  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = {};
    Object.keys(inputValues).forEach((name) => {
      const error = validateForm(name, inputValues[name]);
      if (error && error.length > 0) {
        validationErrors[name] = error;
      }
    });
    if (Object.keys(validationErrors).length > 0) {
      setErrors({ ...errors, ...validationErrors });
      return;
    }

    if (inputValues.email && inputValues.password) {
      if (rememberMe) {
        localStorage.setItem("rememberMe", "true");
        localStorage.setItem("email", inputValues.email);
        localStorage.setItem("password", inputValues.password);
      } else {
        localStorage.removeItem("email");
        localStorage.removeItem("password");
      }
    }

    dispatch(login(inputValues)).then((response) => {
      if (response?.payload?.message === "Login Succesful") {
        saveAccessToken("token", response?.payload?.token);
        navigate("/dashboard");
      }
    });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <MUIGrid
          item
          xs={false}
          sm={6}
          md={8}
          sx={{ backgroundColor: "#F6F1F4" }}
        >
          <img
            src={img}
            style={{ width: "100%", height: "100%" }}
            alt="Survey"
          />
        </MUIGrid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          elevation={6}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5" sx={{ mb: 1 }}>
              Sign In
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(e);
              }}
              sx={{ mt: 1 }}
              autoComplete="off"
            >
              <InputText
                size="medium"
                label="Email"
                name="email"
                type="text"
                variant="outlined"
                placeholder="Email"
                id="email"
                onChange={handleOnChange}
                value={inputValues.email}
                error={errors.email ? true : false}
                errorMessage={errors.email}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailIcon style={{ color: purple[500] }} />
                    </InputAdornment>
                  ),
                }}
              />
              <OutlinedInputPassword
                name="password"
                label="Password"
                placeholder="Password"
                value={inputValues.password}
                onChange={handleOnChange}
                error={errors.password ? true : false}
                errorMessage={errors.password && errors.password}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon style={{ color: purple[500] }} />
                    </InputAdornment>
                  ),
                }}
              />
              <FormControlLabel
                label="Remember Me"
                control={
                  <Checkbox
                    sx={{ fontFamily: "Merriweather" }}
                    size="small"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(!rememberMe)}
                  />
                }
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ my: 2, textTransform: "none" }}
                startIcon={
                  isLoading && (
                    <CircularProgress sx={{ color: "white" }} size={20} />
                  )
                }
              >
                {isLoading ? "Loading..." : "Sign In"}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default withLoader(Login);
