import React from "react";
import { Button } from "@mui/material";

const MUIButton = ({
  href,
  component,
  sx,
  title,
  variant,
  color,
  children,
  Icon,
  onClick,
  ...props
}) => {
  return (
    <Button
      title={title}
      variant={variant}
      color={color}
      sx={sx}
      onClick={onClick}
      href={href}
      component={component}
      {...props}
    >
      {title}
    </Button>
  );
};

export default MUIButton;
