import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import TableHeader from "../TableHeader/TableHeader";
import { Box } from "@mui/material";

export const DataGridTable = (props) => {
  const {
    rows,
    columns,
    getRowId,
    paginationModel,
    onPaginationModelChange,
    pageSizeOptions = [5, 10, 25, 50, 100],
    rowCount = 0,
    paginationMode = "server",
    handleAdd,
    title,
    searchlabel,
    buttonName,
    checkboxSelection,
    rowSelectionModel,
    onRowSelectionModelChange,
    handleFilter,
    value,
    component,
    href,
    departmentMapInputSelect,
    dropdownGroupDepartmentMapList,
    columnHeaderHeight,
  } = props;

  return (
    <Box>
      <TableHeader
        handleAdd={handleAdd}
        title={title}
        searchlabel={searchlabel}
        buttonName={buttonName}
        handleFilter={handleFilter}
        value={value}
        href={href}
        component={component}
        departmentMapInputSelect={departmentMapInputSelect}
        dropdownGroupDepartmentMapList={dropdownGroupDepartmentMapList}
      />
      <DataGrid
        checkboxSelection={checkboxSelection}
        rowSelectionModel={rowSelectionModel}
        onRowSelectionModelChange={onRowSelectionModelChange}
        pagination
        autoHeight
        disableRowSelectionOnClick
        rows={rows}
        columns={columns}
        rowCount={rowCount}
        paginationMode={paginationMode}
        getRowId={getRowId}
        paginationModel={paginationModel}
        pageSizeOptions={pageSizeOptions}
        onPaginationModelChange={onPaginationModelChange}
        columnHeaderHeight={columnHeaderHeight}
        disableColumnMenu
        disableColumnFilter={true}
        sx={{
          "& .MuiDataGrid-columnHeader:focus-within": { outline: "none" },
          "& .MuiDataGrid-cell:focus-within":{ outline: "none" },
          "& .MuiDataGrid-columnHeaders": {
            borderRadius: 0,
            lineHeight: "0px !important",
            marginTop: "30px !important",
          },
          "& .css-gl260s-MuiDataGrid-columnHeadersInner": {
            marginTop: "50px",
            marginTop: "20px",
            marginBottom: "30px",
          },
          "& .css-o8ykra-MuiDataGrid-root": { lineHeight: "0px" },
          "& .css-1jlr3bh-MuiDataGrid-root & .MuiDataGrid-columnHeaders": {
            minHeight: "105px",
          },
          "& css-rtrcn9-MuiTablePagination-root .MuiTablePagination-selectLabel":
            { display: "block !important" },
          "& .MuiDataGrid-sortIcon": {
            display: "none",
          },
        }}
      />
    </Box>
  );
};







