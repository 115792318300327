import React from "react";
import RouteManager from "./Route/RouteManager";
import Theme from "./Theme/Theme";
import { Toaster } from "react-hot-toast";

const App = () => {
  return (
    <Theme>
      <RouteManager />
      <Toaster position="top-right" />
    </Theme>
  );
};

export default App;
