import React from "react";
import { Card } from "@mui/material";

const MUICard = ({ sx, children, variant }) => {
  return (
    <Card variant={variant} sx={sx}>
      {children}
    </Card>
  );
};

export default MUICard;
