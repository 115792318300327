import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Services/API";
import { getFamilyAddListURL, getFamilyDeleteListURL, getFamilyListURL, getFamilyURL, getFamilyUpdateListURL } from "../../Services/ApiConfig";
import toast from "react-hot-toast";

const initialState = {
  familyList: [],
  singlefamilyList: [],
  isLoading: false,
  error: null,
};

/**
 * get family list
 */
export const fetchFamilyList = createAsyncThunk(
  "/familyList/view-family-list",
  async ({ page, per_Page, filters, filter }) => {
    try {
      const response = await axiosInstance.get(getFamilyListURL(filter), {
        params: {
          page: page,
          per_page: per_Page,
          filters: filters,
        },
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }
);

/**
 * fetch single family record
 */
export const fetchSingleFamily = createAsyncThunk(
  "/familyList/sigle-view",
  async (id) => {
    try {
      const response = await axiosInstance.get(getFamilyURL(id));
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

/**
 * post family data
 */
export const createFamily = createAsyncThunk(
  "/create/family",
  async (parameters) => {
    try {
      const response = await axiosInstance.post(getFamilyAddListURL(), parameters);
      if (response.status === 201) {
        toast.success(response.data.messages.success);
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

/**
 * Delete family data
 */
export const deleteFamily = createAsyncThunk(
  "/family/delete/id",
  async (id) => {
    try {
      const response = await axiosInstance.get(getFamilyDeleteListURL(id));
      if (response.status === 200) {
        toast.success(response.data.messages.success);
      }
    } catch (error) {
      console.log(error);
    }
  }
);

/**
 * Update family data
 */
export const updateFamily = createAsyncThunk(
  "/family/update/id",
  async ({ parameters }) => {
    const id = parameters.id;
    const formValues = parameters.formValues;
    try {
      const response = await axiosInstance.post(getFamilyUpdateListURL(id), formValues);
      if (response.status === 201) {
        toast.success(response.data.messages.success);
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  }
);

/**
 * create slice
 */
const familyListSlice = createSlice({
  name: "viewFamily",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFamilyList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchFamilyList.fulfilled, (state, action) => {
        state.familyList = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchFamilyList.rejected, (state) => {
        state.isLoading = false;
        state.familyList = [];
      })

      .addCase(fetchSingleFamily.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(fetchSingleFamily.fulfilled, (state, action) => {
        state.singlefamilyList = action.payload;
        state.isLoading = false;
      })

      .addCase(fetchSingleFamily.rejected, (state) => {
        state.isLoading = false;
        state.singlefamilyList = [];
      })
      .addCase(createFamily.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createFamily.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createFamily.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(updateFamily.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(updateFamily.fulfilled, (state) => {
        state.isLoading = false;
      })

      .addCase(updateFamily.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const getFamilyList = (state) => state.viewFamily.familyList;
export const getSingleFamily = (state) => state.viewFamily.singlefamilyList;
export const getLoadingState = (state) => state.viewFamily.isLoading;

/**
 * Export the reducer
 */
export default familyListSlice.reducer;
