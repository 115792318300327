import React from "react";
import { Typography } from "@mui/material";

const CustomTypography = (props) => {
  const { children, href, component, ...rest } = props;

  return (
    <Typography component={component} href={href} {...rest}>
      {children}
    </Typography>
  );
};

export default CustomTypography;
